import React from 'react'
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useBusiness } from '../../Hooks/Business/useBusiness';
import BusinessCards from '../../Components/OurBusiness/BusinessCards';
import Header from '../../Components/Layout/Header';

function Index() {

  const { searchData, industries, value, setValue } = useBusiness()

  return (
    <div>
       <Header title="Business List" description="Our family is a source of pride, innovation, and entrepreneurship. We showcase our family members' diverse organizations, businesses, and initiatives, which aim to make a positive impact" currentPage="Business List" />
      <section id="constructions" className="constructions section" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
        <div className="container">
          <TabContext value={value} className="p-0">
            <Box className="tabbing pt-2">
              <div className="row p-0">
                <div className="col-md-8 col-xs-12 ">
                  <TabList onChange={(e, value) => {window.scrollTo(0, 0);setValue(value)}}
                    aria-label="lab API tabs example"
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile>
                    <Tab label="All" value="all" />
                    <Tab label="Diamond" value="Gem & Jewellery" />
                    <Tab label="IT" value="Informations & Technology" />
                    <Tab label="Solar" value="Renewable Energy" />
                    <Tab label="Pharmacy" value="Pharmacy Industry" />
                  </TabList>
                </div>
                <div className="col-md-4 col-xs-12 mt-2">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="searchbar">
                      <input className="search_input" type="text" name="" onChange={searchData} placeholder="Search..." />
                      <div className="search_icon"><i className="bi bi-search"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
            <TabPanel value="all">
              <BusinessCards industries={industries} />
            </TabPanel>
            <TabPanel value="Gem & Jewellery">
              <BusinessCards industries={industries} />
            </TabPanel>
            <TabPanel value="Informations & Technology">
              <BusinessCards industries={industries} />
            </TabPanel>
            <TabPanel value="Renewable Energy">
              <BusinessCards industries={industries} />
            </TabPanel>
            <TabPanel value="Pharmacy Industry">
              <BusinessCards industries={industries} />
            </TabPanel>
          </TabContext>
        </div>
      </section>
    </div>
  )
}
export default Index
