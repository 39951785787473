import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";

function Navbar() {
  // const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
  const [logo,setLogo] = useState("../../Assets/Logo/Logo-Dark.png")

  function mobileNavToogle() {
    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
    document.querySelector("body").classList.toggle("mobile-nav-active");
    mobileNavToggleBtn.classList.toggle("bi-list");
    mobileNavToggleBtn.classList.toggle("bi-x");
  }

  function changeLogoOnScroll(){
    window.scrollY > 20 ? setLogo("../../Assets/Logo/Logo_light.png") : setLogo("../../Assets/Logo/Logo-Dark.png")
  }

  document.addEventListener("scroll",changeLogoOnScroll)
  /**
   * Hide mobile nav on same-page/hash NavLinks
   */

  useEffect(() => {
    document.querySelectorAll("#navmenu .Link").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });
  });

  return (
    <div>
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <div className="logo d-flex align-items-center">
            {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
             <img src={logo} style={{width:"150px"}} alt=""/> 
            {/* <h1 className="sitename">
              <span>F</span>amily<span>T</span>ree
            </h1> */}
          </div>

          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <NavLink activeclassname="active" className="Link" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeclassname="active"
                  className="Link"
                  to="/history"
                >
                  Our family
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeclassname="active"
                  className="Link"
                  to="/allmembers"
                >
                  All Members
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="active" className="Link" to="/businesslist">
                  Business
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="active" className="Link" to="/blog">
                  Journal
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeclassname="active"
                  className="Link"
                  to="/gallery"
                >
                  Snap
                </NavLink>
              </li>
            </ul>
            <i
              className="mobile-nav-toggle d-xl-none bi bi-list"
              onClick={mobileNavToogle}
            ></i>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
