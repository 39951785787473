import React, { useState, useEffect } from "react";
import imgback1 from "../../Assets/Main-slide-1.png";
import imgback2 from "../../Assets/Main-slide-2.png";
import "./Mainhero.css";
import { Link } from "react-router-dom";

const Mainhero = () => {
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const [timeTrans] = useState(5000);
  const [slides] = useState([
    {
      backgroundImage: imgback1,
      title: "Welcome to the Dholakia Family Website",
      des:"Celebrate our family's culture and legacy through extraordinary stories that unite us on this journey"
    },
    {
      backgroundImage: imgback2,
      title: "Family Happiness",
      des:"Happiness is having a large, loving, caring, close-knit family in another city."
    }
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (autoPlay) {
        setCurrent((current + 1) % slides.length);
      }
    }, timeTrans);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [autoPlay, timeTrans, slides]);

  const handleDotClick = (index) => {
    setCurrent(index);
  };

  const handleMouseEnter = () => {
    setAutoPlay(false);
  };

  const handleMouseLeave = () => {
    setAutoPlay(true);
  };

  return (
    <section className="intro">
      <div
        className="slider"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ul>
          {slides.map((slide, index) => (
            <li
              key={index}
              style={{ backgroundImage: `url(${slide.backgroundImage})` }}
              className={index === current ? "current" : ""}
            >
              <div className="info d-flex align-items-center">
                <div className="container">
                  <div
                    className="row justify-content-center"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <div className="col-lg-8 text-center">
                      <h2 className="fw-light">
                        {slide.title}
                      </h2>
                      <p>
                       {slide.des}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <ul>
          <nav>
            {slides.map((slide, index) => (
              <Link to=""
                key={index}
                className={index === current ? "current_dot" : ""}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </nav>
        </ul>
      </div>
    </section>
  );
};

export default Mainhero;
