import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../Components/AllMembers/Familytree.css";
import GLightbox from "glightbox";
import { useMember } from "../../Hooks/Members/useMember";
import Header from "../../Components/Layout/Header";
import RelationCard from "../../Components/AllMembers/RelationCard";

function Index() {
  const { getPersonsImages, personsData } = useMember()
  const personalData = {
    Birthday: personsData?.DOB || null,
    Phone: personsData?.Contact ? `${personsData?.Contact}` : null,
    City: personsData?.CurrentCity || null,
    Address: personsData?.Address || null,
  }

  useEffect(() => {
    GLightbox({
      selector: ".glightbox",
      loop: true,
      closeButton: true,
      plugins: {
        footer: {
          displayDescription: false,
          displayCounter: false,
        },
      },
    });
  });

  return (
    <div>
      <main>
        <Header title={personsData?.Name} description="" currentPage="Person detail" />
        <section id="about" className="about section">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4 justify-content-center">
              <div className="col-lg-4">
                <img src={ personsData.Img ? personsData.Img : personsData.Gender === "male" ? "../../Assets/boy.png" : "../../Assets/girl.png"} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-8 content">
                <div className="row">
                  {Object.entries(personalData).map(([key, value], index) => (
                    value && <div className="col-lg-6 col-md-6 ">
                      {
                        key && <div className="bio">
                          {
                            key === "Phone" ? <div key={index}><i className="bi bi-chevron-right"></i><strong>{key}:</strong><span>{value && '********' + value?.slice(-2)}</span></div> : <div key={index}><i className="bi bi-chevron-right"></i><strong>{key}:</strong> <span>{value}</span></div>
                          }
                        </div>
                      }
                    </div>
                  ))}
                </div>
                <p className="py-3">
                I am a passionate and curious person who values connection and growth. I love learning and exploring new ideas, with family and friends providing essential support. I embrace challenges positively, engage in creative pursuits, and strive to live authentically while making meaningful contributions to the world around me.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="Allmembers" className="Allmembers section">
          <div className="container">
            <div className="section-title pb-3 mt-3">
              <h2>Relations</h2>
              {/* <p>
                Magnam dolores commodi suscipit. Necessitatibus eius consequatur
                ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
                quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
                Quia fugiat sit in iste officiis commodi quidem hic quas.
              </p> */}
            </div>
            <div
              className="isotope-layout"
              data-default-filter="*"
              data-layout="masonry"
              data-sort="original-order"
            >
              <div
                className="row gy-4 isotope-container"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {personsData?.paternal && (
                  <RelationCard Id={personsData?.paternal.Id} image={personsData?.paternal.image} relation={personsData?.paternal.relation} name={personsData?.paternal?.Name} DOB={personsData?.paternal?.DOB} DOD={personsData?.paternal?.died} gender={personsData?.paternal?.Gender} />
                )}

                {personsData?.maternal && (
                  <RelationCard Id={personsData?.maternal.Id} image={personsData?.maternal.image} relation={personsData?.maternal.relation} name={personsData?.maternal?.Name} DOB={personsData?.maternal?.DOB} DOD={personsData?.maternal?.died} gender={personsData?.maternal?.Gender} />
                )}
                {personsData?.spouse && (
                  <RelationCard Id={personsData?.spouse.Id} image={personsData?.spouse.image} relation={personsData?.spouse.relation} name={personsData?.spouse.Name}
                    DOB={personsData?.spouse?.DOB} DOD={personsData?.spouse?.died} gender={personsData?.spouse?.Gender} />
                )}
                {personsData?.children.map((personsData) => {
                  return (
                    <RelationCard Id={personsData?.Id} image={personsData?.image} relation={personsData?.relation} name={personsData?.Name}
                      DOB={personsData?.DOB} DOD={personsData?.died} gender={personsData?.Gender} />
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        {
          personsData?.business?.length > 0 && <section id="constructions" className="constructions section">
            <div className="container">
              <div className="row gy-4">
                <div className="section-title pb-3 mt-5">
                  <h2>Businesses</h2>
                  {/* <p>
                    Magnam dolores commodi suscipit. Necessitatibus eius
                    consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                    velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit
                    suscipit alias ea. Quia fugiat sit in iste officiis commodi
                    quidem hic quas.
                  </p> */}
                </div>
                <div className="row">
                  {
                    personsData?.business?.map((val) => {
                      return <>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                          <div className="card-item card-ui">
                            <div className="row">
                              <div className="col-xl-5">
                                <div className="card-bg">
                                  <img
                                    src={val?.img}
                                    alt={val.title}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-7 d-flex align-items-center">
                                <div className="card-body">
                                  <h4 className="card-title">
                                    {val.title}
                                  </h4>
                                  <p>
                                    {val?.short_detail}
                                  </p>
                                  <div className="mt-4">
                                    <Link
                                      to={{ pathname: `/business/${val?.Iid}/${val?.id}` }}
                                      className="readmore stretched-link"
                                    >
                                      Read more <i className="bi bi-arrow-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    })
                  }
                </div>
              </div>
            </div>
          </section>
        }
        {
          personsData?.lifestory_id && <section id="resume" className="resume">
            <div className="container" data-aos="fade-up">
              <div className="section-title pb-3">
                <h2>Life Story</h2>
                {/* <p>
                  Magnam dolores commodi suscipit. Necessitatibus eius consequatur
                  ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
                  quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
                  Quia fugiat sit in iste officiis commodi quidem hic quas.
                </p> */}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  {personsData?.lifestory?.map((story, index) => {
                    return index <
                      Math.round(personsData?.lifestory.length / 2) ? (
                      <div className="resume-item pb-0" key={index}>
                        <h4>{index + 1} . {story.title}</h4>
                        <h5>{story.year}</h5>
                        <p>
                          <em>
                            Rochester Institute of Technology, Rochester, NY
                          </em>
                        </p>
                        <p>{story.description}</p>
                      </div>
                    ) : (
                      <></>
                    );
                  })}
                </div>
                <div className="col-lg-6">
                  {personsData?.lifestory?.map((story, index) => {
                    return index >=
                      Math.round(personsData?.lifestory.length / 2) ? (
                      <div className="resume-item pb-0" key={index}>
                        <h4>{index + 1} . {story.title}</h4>
                        <h5>{story.year}</h5>
                        <p>
                          <em>
                            Rochester Institute of Technology, Rochester, NY
                          </em>
                        </p>
                        <p>{story.description}</p>
                      </div>
                    ) : (
                      <></>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        }


        {
          getPersonsImages?.images?.length > 0 && <div className="container persons-gallery" style={{ paddingBottom: "40px" }}>
            <div className="section-title pb-3 mt-3">
              <h2>Memories</h2>
              {/* <p>
                Magnam dolores commodi suscipit. Necessitatibus eius consequatur
                ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
                quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
                Quia fugiat sit in iste officiis commodi quidem hic quas.
              </p> */}
            </div>
            <div
              className="isotope-layout"
              data-default-filter="*"
              data-layout="masonry"
              data-sort="original-order"
            >
              <div
                className="row isotope-container g-2"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {getPersonsImages?.images.map((img, ind) => {
                  return (
                    <div
                      className="col-lg-3 col-md-6 gallery-item isotope-item filter-remodeling"
                      key={ind}
                    >
                      <a
                        href={img}
                        className="glightbox preview-link"
                      >
                        <div className="gallery-item-img">
                          <img src={img} alt="" />
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      </main>
    </div>
  );
}

export default Index;
