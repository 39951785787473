import React from "react";
import "../../Components/BlogComp/Blog.css";
import { Link } from "react-router-dom";
import data from "../../Components/BlogComp/blogData.json";
import { truncateString } from "../../utils/CommanFun";
import LatestBlog from "../../Components/BlogComp/LatestBlog";
import Header from "../../Components/Layout/Header";

function Index() {
  return (
    <div>
      <main className="main">
      <Header title="Blog" description="Explore cherished memories, adventures, and valuable insights. Dive into our archives for heartwarming stories, parenting tips, and fun activities that inspire connection and joy. Feel free to adjust any part to better fit your blog's tone!" currentPage="Blog" />
        <section id="blog-posts" className="blog-posts section" data-aos="fade-up"
          data-aos-delay="300" data-aos-duration="1000">
          <div id="main-content" className="blog-page">
            <div className="container">
              <div className="row clearfix">
                <div className="col-lg-8 col-md-12 left-box">
                  {
                    data?.blogdata?.map((val) => {
                      return <>
                        <div className="card single_post card-ui mb-3" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                          <div className="body">
                            <div className="img-post">
                              <img className="d-block img-fluid" src={val?.img} alt="" width="100%"  />
                            </div>
                            <button className='tags mb-3'>{val?.topic}</button>
                            <h4><a href="blog-details.html">{val?.title.length > 30 ? truncateString(val?.title, 30) : val?.title}</a></h4>
                            <div className="d-flex gap-3">
                              <div className="author">
                                <i className="bi bi-calendar-check-fill"></i>
                                <div className="media-body">
                                  <span>{val?.postdate}</span>
                                </div>
                              </div>
                              <div className="author">
                                <i className="bi bi-tags-fill"></i>
                                <div className="media-body">
                                  <span>{val?.category}</span>
                                </div>
                              </div>
                            </div>
                            <p>{val?.detail_main}</p>
                          </div>
                          <div className="footer">
                            <div className="author">
                              <i className="bi bi-person-fill"></i>
                              <div className="media-body">
                                <label>{val?.person}</label>
                              </div>
                            </div>
                            <div className="actions">
                              <Link to={`/blog/${val?.id}`} className="readmore stretched-link"><span>Read More</span>
                                <i className="bi bi-arrow-right"></i></Link>
                            </div>
                          </div>
                        </div>
                      </>
                    })
                  }
                </div>
                <div className="col-lg-4 col-md-12 right-box">
                  <div className="col-md-12 col-xs-12 mb-3">
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="searchbar">
                        <input className="search_input" type="text" name="" placeholder="Search..." />
                        <div className="search_icon"><i className="bi bi-search"></i></div>
                      </div>
                    </div>
                  </div>
                  <LatestBlog data={data} />
                  <div className="blog-aside mt-3 widget widget-latest-post card-ui">
                    <div className="widget-title">
                      <h3>Categories Clouds</h3>
                    </div>
                    <div className="widget-body">
                      <ul className="list-unstyled categories-clouds m-b-0">
                        <li><button className='tags'>eCommerce</button></li>
                        <li><button className='tags'>Microsoft Technologies</button></li>
                        <li><button className='tags'>Creative UX</button></li>
                        <li><button className='tags'>Wordpress</button></li>
                        <li><button className='tags'>Angular JS</button></li>
                        <li><button className='tags'>Enterprise Mobility</button></li>
                        <li><button className='tags'>Website Design</button></li>
                        <li><button className='tags'>HTML5</button></li>
                        <li><button className='tags'>Infographics</button></li>
                        <li><button className='tags'>Wordpress Development</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
export default Index;
