import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import GLightbox from "glightbox";
import NotFound from "../Layout/NotFound";

function GalleryImagesCard({ data }) {
  useEffect(() => {
    GLightbox({
      selector: ".glightbox",
      loop: true,
      closeButton: true,
      plugins: {
        // Customize the footer content
        footer: {
          displayDescription: false, // Display description (title) from `data-title` attribute
          displayCounter: false,    // Optionally hide the counter
        }
      }
    });
  });

  return (
    <>
      {data?.length > 0 ? <div
        className="row gy-4 isotope-container"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-duration="1000"
      >
        {data?.map(({ title, thumbnail_url, id }) => {
          return (
            <div
              key={id}
              className="col-lg-4 col-sm-6 gallery-item isotope-item "
            >
              <div className="portfolio-content h-100 card-ui">
                <img
                  src={thumbnail_url}
                  className="img-fluid"
                  alt=""
                />
                <div className="album-info w-100 p-3 ">
                  <p className="mb-0">{title}</p>
                  <div className="link-div">
                    <Link
                      className="Link"
                      to={{
                        pathname: `/gallery/${id}`,
                      }}
                    >
                      More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div> : <NotFound />}
    </>

  );
}

export default GalleryImagesCard;
