import React from 'react';
import { Link } from 'react-router-dom';
import { removeSubstrings } from '../../utils/CommanFun';

const RelationCard = ({ Id, image, relation, name, gender, DOB, DOD }) => {
    return (
        <Link
            className="col-lg-3 col-sm-6 col-6 tree-link"
            key={Id}
            to={{ pathname: `/allmembers/${Id}` }}
        >
            <article className="position-relative  border overflow-hidden rounded-3">
                <div className="post-img position-relative overflow-hidden">
                    <img
                        src={image ? image : gender === "male" ? "../../Assets/boy.png" : "../../Assets/girl.png"}
                        className="img-fluid w-100"
                        alt=""
                    />
                    <span className="post-date">
                        {relation}
                    </span>
                </div>
                <div className="post-content d-flex flex-column align-items-center justify-content-between p-4">
                    <h3 className="post-title mb-0 fw-bolder">
                        {removeSubstrings(
                            name?.split(" ")[0] +
                            " " +
                            name?.split(" ")[2]
                        )}
                    </h3>
                    <span className="d-block fw-bolder">
                        {
                            DOB ? <>{DOD ? DOB + " - " + DOD : DOB}{" "}</> : ""
                        }
                    </span>
                </div>
            </article>
        </Link>
    )
}

export default RelationCard
