import { useMemo } from "react"
import { useParams } from "react-router-dom";
import data from "../../Components/BlogComp/blogData.json";

export const useBlog = ()=>{
    
    const { id: paramid } = useParams();
    
    let filterData = data.blogdata.filter(({ id }) => {
      return id === parseInt(paramid);
    });

    return useMemo(()=>({
        filterData
    }),[filterData])
}