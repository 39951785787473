import React from "react";
import { useBlog } from "../../Hooks/Blog/useBlog";
import data from "../../Components/BlogComp/blogData.json";
import LatestBlog from "../../Components/BlogComp/LatestBlog";
import Header from "../../Components/Layout/Header";

function Index() {
  const { filterData } = useBlog();

  let {
    img,
    postdate,
    detail_main,
    quote,
    topic_detail_1,
    tag,
    topic,
    title
  } = filterData[0];

  return (
    <div>
      <div>
        <main className="main">
        <Header title="Blog Details" description="" currentPage="Blog Details" />
          <div className="blog-single gray-bg">
            <div className="container" data-aos="fade-up"
              data-aos-delay="200">
              <div className="row align-items-start">
                <div className="col-lg-8 ">
                  <article className="article">
                    <div className="article-img">
                      <img src={img} title="" alt="" width="100%" />
                    </div>
                    <div className="article-title">
                      <button className='tags mb-3'>{topic}</button>
                      <h2>{title}</h2>
                      <div className="d-flex gap-3">
                        <div className="author">
                          <i className="bi bi-calendar-check-fill"></i>
                          <div className="media-body">
                            <span>{postdate}</span>
                          </div>
                        </div>
                        <div className="author">
                          <i className="bi bi-tags-fill"></i>
                          <div className="media-body">
                            <span>{topic}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="article-content">
                      <p>{detail_main}</p>
                      <p>{topic_detail_1}</p>
                      <blockquote>
                        <p>{quote}</p>
                      </blockquote>
                      <p>{topic_detail_1}</p>
                    </div>
                  </article>
                </div>
                <div className="col-lg-4 right-box">
                  {
                    tag?.length > 0 && <div className="widget blog-aside card-ui widget-tags my-3">
                      <div className="widget-title">
                        <h3>Latest Tags</h3>
                      </div>
                      <div className="widget-body">
                        <div className="nav tag-cloud">
                          {
                            tag?.map((value) => <button className='tags'>{value}</button>)
                          }
                        </div>
                      </div>
                    </div>
                  }
                  <LatestBlog data={data} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Index;
