import React from "react";
import "./Recentblog.css";
import data from "./blogData.json";
import { Link } from "react-router-dom";

function Recentblog() {
  return (
    <div>
      <section id="recent-blog-posts" className="recent-blog-posts section">
        <div className="container section-title" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
          <h2 className="recent-blog-title">The Family Journal</h2>
          <p>
            Experience comes from actually doing something, and you can’t gain
            experience until you do.
          </p>
        </div>
        <div className="container">
          <div className="row gy-5" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
            {
              data?.blogdata?.slice(0, 3)?.map((val, index) => {
                return <div className="col-xl-4 col-md-6">
                  <div
                    className="post-item position-relative h-100 rounded-bottom rounded-bottom card-ui"
                  >
                    <div className="post-img position-relative overflow-hidden rounded-top rounded-top">
                      <img src={val?.img} alt="" />
                    </div>
                    <div className="post-content d-flex flex-column">
                      <h3 className="post-title">
                        {val?.title}
                      </h3>
                      <div className="meta d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-person"></i>{" "}
                          <span className="ps-2">{val?.person}</span>
                        </div>
                        <span className="px-3 text-black-50">/</span>
                        <div className="d-flex align-items-center">
                          <i className="bi bi-folder2"></i>{" "}
                          <span className="ps-2">{val?.topic}</span>
                        </div>
                      </div>
                      <hr />
                      <Link to={`/blog/${val?.id}`} className="readmore stretched-link">
                        <span>Read More</span>
                        <i className="bi bi-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
        </div>
      </section>
    </div>
  );
}

export default Recentblog;
