import { useCallback, useEffect, useMemo, useState } from "react"
import data from "../../Components/OurBusiness/BussinessData.json";
import { useLocation, useParams } from "react-router-dom";
import memberData from "../../Components/AllMembers/memberData.json"

export const useBusiness = () => {
    let [industries, setIndustries] = useState(data.businessDetail);
    const location = useLocation();
    const [value, setValue] = useState(location?.state?.Business ? location?.state?.Business : "all");
    const [filter, setFilter] = useState([]);
    const { id1: paramid1, id2: paramid2 } = useParams();
    const [getBusinessDetail, setGetBusinessDetail] = useState(null);
    
    // get businees and find the connected person
    useEffect(() => {
        const businessDetail = filter[0]?.companies?.filter(({ id }) => {
            return id === paramid2;
        })[0];

        if (businessDetail) {
            if (!businessDetail.connectedPerson) {
                businessDetail.connectedPerson = [];
            }
            memberData?.Member?.forEach((val) => {
                val?.business?.forEach((element) => {
                    if (element?.Industry_id === businessDetail?.Iid && element?.business_id === businessDetail?.id) {
                        const exists = businessDetail.connectedPerson.some(person => person.Id === val?.Id);
                        if (!exists) {
                            businessDetail.connectedPerson.push({ "Id": val?.Id, "Name": val?.Name });
                        }
                    }
                });
            });
        }
        setGetBusinessDetail(businessDetail);
    }, [filter, paramid2]);

    // search business by name
    let searchData = useCallback(({ target: { value } }) => {
        const searchValue = value.toLowerCase();
        const filteredBusinesses = data?.businessDetail?.map(industry => {
            const matches = industry.companies.filter(business => business?.title.toLowerCase().includes(searchValue));
            return {
                ...industry,
                companies: matches
            };
        });
        if (searchValue.length > 0) {
            const nonEmptyIndustries = filteredBusinesses.filter(industry => industry.companies.length > 0);
            setIndustries(nonEmptyIndustries);
        } else {
            setIndustries(data.businessDetail);
        }
    }, []);

    useEffect(() => {
        let filterData = data.businessDetail.filter(({ Iid }) => {
            return Iid === paramid1;
        });
        setFilter(filterData)
        if (value === 'all') {
            setIndustries(data.businessDetail);
        } else {
            const filterData = data?.businessDetail?.filter(industry => industry?.Industry_Title === value);
            setIndustries(filterData);
        }
    }, [paramid1, value])

    return useMemo(() => ({
        getBusinessDetail,
        searchData,
        filter,
        industries,
        setIndustries,
        value,
        setValue
    }), [getBusinessDetail, filter, value, setValue, searchData, industries, setIndustries])
}