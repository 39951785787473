import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "imagesloaded/imagesloaded.pkgd.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "glightbox/dist/js/glightbox.min.js";
import "glightbox/dist/js/glightbox.js";
import "glightbox/dist/css/glightbox.css";
import "glightbox/dist/css/glightbox.min.css";
import "isotope-layout/dist/isotope.pkgd.js";
import "imagesloaded/imagesloaded.pkgd.min.js";
import "aos/dist/aos.css"
import "aos/dist/aos.js"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function toggleScrolled() {
  const selectBody = document.querySelector("body");
  const selectHeader = document.querySelector("#header");
  if (
    !selectHeader.classList.contains("scroll-up-sticky") &&
    !selectHeader.classList.contains("sticky-top") &&
    !selectHeader.classList.contains("fixed-top")
  )
    return;
  window.scrollY > 20
    ? selectBody.classList.add("scrolled")
    : selectBody.classList.remove("scrolled");
  
}

document.addEventListener("scroll", toggleScrolled);
window.addEventListener("load", toggleScrolled);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
reportWebVitals();
