import React, { useEffect, useState } from "react";
import "./UpComCeleb.css";
import { Link, useNavigate } from "react-router-dom";
import familyMember from "../../Components/AllMembers/memberData.json";
import moment from "moment";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import personImg from "../../Assets/man.png";
import girl from "../../Assets/girl.png"

function Upcomingcelebrations() {

  const [value, setValue] = useState("birthday");
  const [nextBirthdays, setNextBirthdays] = useState([])
  const navigate = useNavigate();

  function findUpcomingBirthdays(members) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to the start of today
    const currentYear = today.getFullYear();
  
    const upcomingBirthdays = members.map((member) => {
      let day, month;
  
      // Determine if we're looking for birthdays or DOMs
      if (value === "birthday") {
        const DOB = member?.DOB && moment(member?.DOB, "DD MMM YYYY", true).isValid() ? member?.DOB : moment(member?.DOB, "DD-MM-YYYY").format("DD MMM YYYY");
        const date = moment(DOB, 'DD MMM YYYY');
        [day, month] = date?.format('DD-MM-YYYY')?.split("-");
      } else {
        if (member?.DOM) {
          const date = moment(member?.DOM, 'DD MMM YYYY');
          [day, month] = date?.format('DD-MM-YYYY')?.split("-");
        }
      }
  
      if (day !== undefined && month !== undefined) {
        const birthDate = new Date(`${currentYear}-${month}-${day}`);
  
        // If the birthDate is before today, set it to next year
        if (birthDate < today) {
          birthDate.setFullYear(currentYear + 1);
        }
  
        // Include today's date as well
        if (birthDate >= today) { // This condition includes today
          return {
            Id: member?.Id,
            Name: member?.Name,
            DOB: member?.DOB && moment(member?.DOB, "DD MMM YYYY", true).isValid() ? member?.DOB : moment(member?.DOB, "DD-MM-YYYY").format("DD MMM YYYY"),
            DOM: member?.DOM,
            date: birthDate,
            image: member?.Gender === "male" ? personImg : girl,
          };
        }
      }
  
      return false; // Return false if no valid date is found
    });
  
    // Filter out invalid entries
    const accurateData = upcomingBirthdays.filter(item => item !== false);
  
    // Sort the upcoming birthdays by date, starting from today
    let data = accurateData.sort((a, b) => a.date - b.date);
  
    return data.slice(0, 4); // Return the first 4 upcoming events
  }

  useEffect(() => {
    const evets = findUpcomingBirthdays(familyMember?.Member);
    setNextBirthdays(evets);
    // eslint-disable-next-line
  }, [value])



  return (
    <section className="section upcoming-cele-parent" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div className="container section-title">
        <h2 className="recent-blog-title">Celebrations Ahead</h2>
        <p>
          Join us in celebrating our upcoming events filled with joy
          ,excitement, and unforgettable memories!
        </p>
      </div>
      <div className="container upcoming-celebration-main ">
        <div className="row ">
          <TabContext value={value}>
            <Box className="mt-2">
              <div className="d-flex justify-content-center align-items-center gap-0">
                <TabList
                  onChange={(e, value) => { setValue(value) }}
                  aria-label="lab API tabs example"
                  scrollButtons="auto"
                  variant="scrollable"
                  // visibleScrollbar="true"
                  allowScrollButtonsMobile
                >
                  <Tab label="Birthday" value="birthday" />
                  <Tab label="Anniversary" value="Anniversary" />
                </TabList>
              </div>
            </Box>
            <TabPanel value="birthday">
              <div className="row m-1">
                {nextBirthdays?.map((value, index) => {
                  return (
                    <div
                      data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000"
                      className="p-2 col-xs-12 col-md-6 col-6  col-xl-3 pointer"
                      key={index}
                      onClick={() => navigate(`/allmembers/${value?.Id}`)}
                    >
                      <article
                        className="position-relative overflow-hidden rounded-3 uplcoming-celeb-card card-ui"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="post-img position-relative overflow-hidden h-75">
                          <img src={value?.image} alt="" />
                          <span className="post-date">
                            <span className="date">{moment(value?.DOB).date()}</span>
                            <br></br>
                            <span className="month">{moment(value?.DOB).format("MMM")}</span>
                          </span>
                        </div>
                        <div className="post-content d-flex flex-column align-items-center justify-content-between p-4">
                          <h3 className="post-title fs-5 mb-0 fw-bolder">{value?.Name?.split(" ")[0] + " " + value?.Name.split(" ")[2]}</h3>
                          <span className="d-block years">
                            ({moment(value?.DOB, 'DD MMM YYYY').year()})
                          </span>
                        </div>
                      </article>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel value="Anniversary">
              <div className="row">
                {nextBirthdays?.map((value, index) => {
                  return (
                    <div
                      data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000"
                      className="col-xs-12 col-md-6 col-6  col-xl-3 pointer"
                      key={index}
                      onClick={() => navigate(`/allmembers/${value?.Id}`)}
                    >
                      <article
                        className="position-relative overflow-hidden rounded-3 uplcoming-celeb-card card-ui"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="post-img position-relative overflow-hidden h-75">
                          <img src={value?.image} alt="" />
                          <span className="post-date">
                            <span className="date">{moment(value?.DOM).date()}</span>
                            <br></br>
                            <span className="month">{moment(value?.DOM).format("MMM")}</span>
                          </span>
                        </div>

                        <div className="post-content d-flex flex-column align-items-center justify-content-between p-4">
                          <h3 className="post-title fs-5 mb-0 fw-bolder">{value?.Name.split(" ")[0] + " " + value?.Name.split(" ")[2]}</h3>
                          <span className="d-block years">
                            ({moment(value?.DOM, 'DD MMM YYYY').year()})
                          </span>
                        </div>
                      </article>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </TabContext>
        </div>
        <div style={{ display: "flex", justifyContent: "end", paddingTop: "10px" }}>
          <Link
            to="/upcomingcelebrations"
            style={{
              color: "#feb900",
              fontWeight: "500",
              textDecoration: "none",
            }}
          >
            View More &nbsp;<i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Upcomingcelebrations;
