import React from 'react'
import { Link } from 'react-router-dom'

const Header = ({ title, description ,currentPage}) => {
    return (
        <div
            className="page-title"
            style={{ backgroundColor: "#393939" }}
        >
            <div
                className="container position-relative"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
            >
                <h1>{title}</h1>
                <p>{description}</p>
                <nav className="breadcrumbs">
                    <ol>
                        <li><Link className="Link" to="/"> Home</Link></li>
                        <li className="current">{currentPage}</li>
                    </ol>
                </nav>
            </div>
        </div>
    )
}

export default Header
