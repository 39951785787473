import React from "react";
import "../../Components/OurBusiness/businessdetails.css";
import { useBusiness } from "../../Hooks/Business/useBusiness";
import Header from "../../Components/Layout/Header";
import { Link } from "react-router-dom";

function Index() {

  const { getBusinessDetail, filter } = useBusiness();

  return (
    <>
      <Header title={filter[0]?.Industry_Title} description="" currentPage={filter[0]?.Industry_Title} />
      <section id="project-details" className="project-details section">
        <div className="container" data-aos="fade-up" data-aos-duration="1000">
          <div className="business_detail_img">
            <img src={getBusinessDetail?.img} style={{ height: "500px" }} alt="" />
          </div>
          <div className="row justify-content-between gy-4 mt-4">
            <div className="col-lg-8" data-aos="fade-up" data-aos-duration="1000">
              <div className="portfolio-description">
                <h2>{getBusinessDetail?.title}</h2>
                <p>{getBusinessDetail?.short_detail}</p>
                {getBusinessDetail?.business_description.map(({ id, text }) => {
                  return (
                    <p key={id}>{text}</p>
                  )
                })}
              </div>
            </div>
            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
              <div className="portfolio-info">
                <h3>Business Information</h3>
                <ul>
                  <li>
                    <strong>Business</strong>{getBusinessDetail?.title}
                  </li>
                  <li>
                    <strong>Address</strong>{getBusinessDetail?.address}
                  </li>
                  <li>
                    <strong>Since</strong> {getBusinessDetail?.since}
                  </li>
                  <li>
                    <strong>Business URL</strong>{" "}
                    <a href={getBusinessDetail?.business_url}>{getBusinessDetail?.business_url}</a>
                  </li>
                  <li >
                    <strong>Connected Person</strong>{" "}
                    <div className="connectedPerson">
                      {
                        getBusinessDetail?.connectedPerson?.map((value, index) => <div><Link key={index} to={`/allmembers/${value?.Id}`} >{value?.Name}</Link></div>)
                      }
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
