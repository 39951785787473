import React, { useEffect, useState } from 'react'
import "./UpcomingCelebcal.css"
import RevoCalendar from 'revo-calendar';
import familyMember from "../../Components/AllMembers/memberData.json";
import Header from '../Layout/Header';
import moment from 'moment';

function Upcomingcelebcal() {
  const [evevts, setEvets] = useState([]);

  function convertToCurrentYearTimestamp(dateS) {
    const dateString = dateS && moment(dateS, "DD MMM YYYY", true).isValid() ? dateS : moment(dateS, "DD-MM-YYYY").format("DD MMM YYYY");
    const acutaldate = moment(dateString, 'DD MMM YYYY')
    const [day, month] = acutaldate?.format('DD-MM-YYYY')?.split("-");
    const currentYear = new Date().getFullYear();
    const date = new Date(currentYear, month - 1, day);
    return date.getTime();
  }

  useEffect(() => {
    const events = [];
    familyMember?.Member?.forEach(individual => {
      individual.bornTimestamp = individual?.DOB ? convertToCurrentYearTimestamp(individual?.DOB) : null;
      individual.anniversaryTimestamp = individual?.DOM ? convertToCurrentYearTimestamp(individual?.DOM) : null;
    });
    familyMember?.Member.forEach(individual => {
      if (individual.bornTimestamp) {
        events.push({
          name: `${individual.Name}'s birthday`,
          date: individual.bornTimestamp,
          allDay: true
        });
      }
      if (individual.anniversaryTimestamp) {
        events.push({
          name: `${individual.Name}'s anniversary`,
          date: individual.anniversaryTimestamp,
          allDay: true
        });
      }
    });
    setEvets(events);
  }, [])

  return (
    <div>
      <Header title="Birthdays & Aniversaries" description="Stay informed about all upcoming family events, reunions, and gatherings with our interactive event calendar. This calendar provides a schedule of events throughout the year, allowing you to plan ahead and mark your calendars for family get-togethers. " currentPage="Celebrations" />
      <div className='container my-5'>
        <RevoCalendar
          style={{
            borderRadius:"5px",
          }}
          events={evevts}
          primaryColor="#feb900"
          indicatorColor="#feb900"
          secondaryColor="#fff"
          showDetailToggler={false}
          detailDateFormat="DD MMM YYYY"
          sidebarWidth={180}
        />
      </div>
    </div>
  )
}

export default Upcomingcelebcal;

