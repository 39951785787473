import React from "react";
import Mainhero from "../../Components/NavbarComp/Mainhero.jsx";
import Upcomingcelebrations from "../../Components/UpcomingCelebration/Upcomingcelebrations.jsx";
import Recentblog from "../../Components/BlogComp/Recentblog.jsx";
import Recentgallery from "../../Components/GalleryComp/Recentgallery.jsx";
import Familystats from "../../Components/FamilyStats/Familystats.jsx";
import Ourbusiness from "../../Components/OurBusiness/Ourbusiness.jsx";
import About from "../About/About.jsx";

function Home() {
  return (
    <div>
      <Mainhero />
      <Familystats/>
      <About/>
      <Upcomingcelebrations />
      <Recentblog />
      <Ourbusiness/>
      <Recentgallery />
    </div>
  );
}

export default Home;
