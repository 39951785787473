import React from "react";
import { Link } from "react-router-dom";
import "./Businesses.css";
import NotFound from "../Layout/NotFound";

const BusinessCards = ({ industries }) => {

  return (
    <>
      {industries?.length > 0 ? (
        <div className="constructions">
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            {industries?.map(({ Iid, Industry_Title, companies },index) => {
              return (
                <div
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                >
                  {companies?.length > 0 && (
                    <div className="Generation-title mt-3" key={index}>
                      <h2>
                        <i className="bi bi-diamond"></i> {Industry_Title}
                      </h2>
                    </div>
                  )}
                  <div style={{display:"flex", flexWrap:"wrap", gap:"20px", marginTop:"20px"}}>
                  {companies?.map(({ id, img, title, short_detail },index) => {
                    return (
                      <>
                        <div className="industry-card-main" style={{width:"48%"}} key={index}>
                          <div className="card-item card-ui">
                            <div className="row">
                              <div className="col-xl-5">
                                <div className="card-bg">
                                  <img src={img} alt={title} />
                                </div>
                              </div>
                              <div className="col-xl-7 d-flex align-items-center">
                                <div className="card-body">
                                  <h4 className="card-title">{title}</h4>
                                  <p>{short_detail}</p>
                                  <div className="mt-4">
                                    <Link
                                      to={{
                                        pathname: `/business/${Iid}/${id}`,
                                      }}
                                      className="readmore stretched-link"
                                    >
                                      Read more{" "}
                                      <i className="bi bi-arrow-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default BusinessCards;
