import React from 'react'
import './Familystats.css'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor';

function Familystats() {
  return (
    <div className='stats-main'>
      <section id="stats-counter" className="stats-counter section">
        <div className="container section-title" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
          <h2>Family Stats</h2>
          <p>Counting the moments that matter, and cherishing the memories that last</p>
        </div>

        <div className="container">

          <div className="row gy-4" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">

            <div className="col-lg-3 col-md-6">
              <div className="stats-item d-flex align-items-center w-100 h-100 card-ui">
                <i className="bi bi-building color-blue flex-shrink-0"></i>
                <div>
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>{({ isVisible }) => (<div style={{ height: 48 }}>{isVisible ? <CountUp end={58} /> : null}</div>)}</VisibilitySensor>
                  <p>Businesses</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item d-flex align-items-center w-100 h-100 card-ui">
                <i className="bi bi-journal-richtext color-orange flex-shrink-0"></i>
                <div>
                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>{({ isVisible }) => (<div style={{ height: 48 }}>{isVisible ? <CountUp end={120} /> : null}</div>)}</VisibilitySensor>
                  <p>Memories</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item d-flex align-items-center w-100 h-100 card-ui">
                <i className="bi bi-trophy color-green flex-shrink-0"></i>
                <div>
                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>{({ isVisible }) => (<div style={{ height: 48 }}>{isVisible ? <CountUp end={82} /> : null}</div>)}</VisibilitySensor>
                  <p>Achivements</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item d-flex align-items-center w-100 h-100 card-ui">
                <i className="bi bi-people color-pink flex-shrink-0"></i>
                <div>
                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>{({ isVisible }) => (<div style={{ height: 48 }}>{isVisible ? <CountUp end={1500} /> : null}</div>)}</VisibilitySensor>
                  <p>Family Members</p>
                </div>
              </div>
            </div>

          </div>

        </div>

      </section>
    </div>
  )
}

export default Familystats
