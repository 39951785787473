import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./Components/NavbarComp/Navbar.jsx";
import Footer from "./Components/Layout/Footer.jsx";
import Blog from "./pages/Blog/Index.jsx";
import Gallery from "./pages/Gallery/Index.jsx";
import Familyhistory from "./pages/History/Index.jsx";
import Viewalbum from "./Components/GalleryComp/Viewalbum.jsx";
import Allmembers from "./pages/AllMembers/Index.jsx";
import Familytree from "./pages/MemberDetail/Index.jsx";
import Blogdetails from "./pages/BlogDetail/Index.jsx";
import Businessdetail from "./pages/BusinessDetail/Index.jsx";
import Upcomingcelebcal from "./Components/UpcomingCelebration/Upcomingcelebcal.jsx";
import BusinessList from "./pages/BusinessList/Index.jsx";
import Home from "./pages/Home/Index.jsx";
import Aos from "aos"
import "./App.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    Aos.init({
      once: false
    })
  }, [])
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/allmembers" element={<Allmembers />} />
        <Route path="/upcomingcelebrations" element={<Upcomingcelebcal />} />
        <Route path="/businesslist" element={<BusinessList />} />
        <Route path="/allmembers/:id" element={<Familytree />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<Blogdetails />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/history" element={<Familyhistory />} />
        <Route path="/gallery/:id" element={<Viewalbum />} />
        <Route path="/business" element={<Businessdetail />} />
        <Route path="/business/:id1/:id2" element={<Businessdetail />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
