import React from 'react'

const NotFound = () => {
    return (
        <div>
            <div className='d-flex justify-content-center flex-column align-items-center' style={{  marginTop: "30px" }}>
                <img src="../Assets/svg/not-found.svg" height={100} width={100} alt="not found" />
                <h1 className="text-center" style={{ fontWeight: "bolder" }}>Data Not Found</h1>
            </div>
        </div>
    )
}

export default NotFound
