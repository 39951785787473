import React from 'react';
import { Link } from 'react-router-dom';

const LatestBlog = ({ data }) => {
    return (
        <div className=" blog-aside mb-3 widget widget-latest-post card-ui">
            <div className="widget-title">
                <h3>Latest Post</h3>
            </div>
            <div className="widget-body">
                {
                    data?.blogdata.slice(0, 3).map((val) => {
                        return <>
                            <div className="d-flex justify-content-between latest-post-aside media">
                                <div className="lpa-left media-body">
                                    <div className="lpa-title">
                                        <h5><Link to={`/blog/${val?.id}`}>{val?.title}</Link></h5>
                                    </div>
                                    <div className="lpa-meta">
                                        <Link to={`/blog/${val?.id}`} className="name">
                                            <i className="fa fa-pencil-square-o"></i>
                                            {val?.person}
                                        </Link>
                                        <Link to={`/blog/${val?.id}`} className="date">
                                            {val?.postdate}
                                        </Link>
                                    </div>
                                </div>
                                <div className="lpa-right">
                                    <Link to={`/blog/${val?.id}`}>
                                        <img src={val.img} title="" alt="" width={100} />
                                    </Link>
                                </div>
                            </div>
                        </>
                    })
                }
            </div>
        </div>
    )
}

export default LatestBlog
