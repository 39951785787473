import React from "react";
import "../../Components/FamilyHIstory/Familyhistory.css";
import FamilyhistoryImg1 from "../../Assets/pngtree.jpg";
import dholakia from "../../Assets/Dholakiya.png"
import historyImg2 from "../../Assets/family-history-1.png"
import { Link } from "react-router-dom";
import Header from "../../Components/Layout/Header";


function Index() {

  return (
    <div>
      <main className="main">
        <Header title="The Journey" description="Learn about our family's historical journey, from its  humble beginnings to the present day. Explore the stories of our ancestors and the pivotal moments that shaped us." currentPage="The Journey" />
        <section id="about-family-details" className="about-family-details section">
          <div className="container">
            <div className="row justify-content-between about-family-item ">
              <div
                className="col-lg-6 order-2 m-0 p-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <video autoPlay={true} muted loop playsInline preload="metadata" controls={true} style={{ height: "100%", width: "100%" }}>
                  <source src="../../Assets/Govindkaka-Introduction_1.mp4" type="video/mp4" />
                </video>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="content">
                  <h3>The Journey</h3>
                  <p>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>
                    &nbsp; Learn about our family's historical journey, from its
                    humble beginnings to the present day. Explore the stories of
                    our ancestors and the pivotal moments that shaped us.
                  </p>
                  <p>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>
                    &nbsp; Discover the rich tapestry of our family's history.
                    Explore the significant milestones, achievements, and
                    transformative moments that have shaped us over the years.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-between about-family-item">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <video autoPlay={true} muted loop playsInline preload="metadata" controls={true} style={{ height: "100%", width: "100%" }}>
                  <source src="../../Assets/about-section2.mp4" type="video/mp4" />
                </video>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="content">
                  <h3>Personal Stories and Interviews </h3>
                  <p>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>Our senior family members hold a treasure trove of
                    memories and wisdom. In this section, we invite you to delve
                    into their personal stories and insights through interviews
                    and written narratives.
                  </p>
                  <p>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>Learn about their experiences, challenges, and the
                    valuable life lessons they've passed down through the
                    generations.
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-between about-family-item">
              <div
                className="col-lg-6 d-flex align-items-center order-2 order-lg-1"
                // style={{ height: "100%" }}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="content">
                  <h3>Historical Documents and Artifacts </h3>
                  <ul>
                    <p>
                      <i
                        className="bi bi-patch-check flex-shrink-0"
                        style={{ color: "#feb900" }}
                      ></i>Documents, photographs, and cherished artifacts
                      that offer a glimpse into our family's past. These
                      precious relics tell stories of resilience, perseverance,
                      and the enduring spirit of our family.
                    </p>
                    <p>
                      <i
                        className="bi bi-patch-check flex-shrink-0"
                        style={{ color: "#feb900" }}
                      ></i>Explore letters, diaries, and heirlooms that have
                      been lovingly preserved for generations.
                    </p>
                  </ul>
                  <p></p>
                </div>
              </div>

              <div
                className="col-lg-6 order-1 order-lg-2"
                // style={{ height: "100%" }}
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  src={FamilyhistoryImg1}
                  // style={{ height: "100%" }}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="row justify-content-between about-family-item ">
              <div className="col-lg-6 d-flex align-items-center order-2">
                <ul
                  className="history history-tabs"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <li className="history-item">
                    <Link className="history-link">
                      <i className="bi bi-binoculars"></i>
                      <div>
                        <h4 className="d-none d-lg-block">Our Family</h4>
                        <p>
                          We are a diverse and close-knit family dedicated to
                          preserving our cultural heritage and values. Our story
                          spans generations, and we take pride in our shared
                          history.
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="history-item">
                    <div className="history-link">
                      <i className="bi bi-box-seam"></i>
                      <div>
                        <h4 className="d-none d-lg-block">Our Culture</h4>
                        <p>
                          At the heart of our family is a rich cultural heritage
                          that we celebrate through traditions, festivals, and
                          shared values.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6">
                <div
                  className="tab-content"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div
                    className="tab-pane fade active show"
                    style={{ width: "100%" }}
                  >
                    <img
                      src={dholakia}
                      alt=""
                      style={{ width: "100%" }}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-between about-family-item">
              <div
                className="col-lg-6 d-flex align-items-center"
                // style={{ height: "100%" }}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="content">
                  <h3>We hold these principles dear.</h3>
                  <p>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>
                    &nbsp;
                    <span style={{ fontWeight: "bolder" }}>
                      Family First :{" "}
                    </span>
                    Family is the cornerstone of our lives. We prioritize love,
                    support, and togetherness.
                  </p>
                  <p>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>
                    &nbsp;
                    <span style={{ fontWeight: "bolder" }}>Respect : </span>We
                    value respect for one another and for our cultural roots.
                  </p>
                  <p>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>
                    &nbsp;
                    <span style={{ fontWeight: "bolder" }}>Generosity : </span>
                    Giving back to the community and supporting one another is a
                    fundamental principle.
                  </p>
                  <p>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>
                    &nbsp;
                    <span style={{ fontWeight: "bolder" }}>Tradition : </span>We
                    honor and uphold our traditions, passing them on to the next
                    generation.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  src={historyImg2}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <div className="row justify-content-between about-family-item">
              <div
                className="col-lg-6 order-2 d-flex align-items-center"
                // style={{ height: "100%" }}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="content">
                  <h3>Family Mission and Vision</h3>
                  <ul>
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>
                    &nbsp;
                    <span style={{ fontWeight: "bolder" }}>Mission : </span>Our
                    mission is to foster a strong, closely-knit family that
                    upholds its cultural heritage, supports one another, and
                    contributes positively to society. <br /> <br />
                    <i
                      className="bi bi-patch-check flex-shrink-0"
                      style={{ color: "#feb900" }}
                    ></i>
                    &nbsp;
                    <span style={{ fontWeight: "bolder" }}>Vision : </span>We
                    envision a future where our family continues to grow and
                    flourish while maintaining our cultural identity, values,
                    and unity. <br /> <br />
                  </ul>
                  <p></p>
                </div>
              </div>

              <div
                className="col-lg-6"
                // style={{ height: "100%" }}
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  src="../Assets/family-mission-1.png"
                  // style={{ height: "100%" }}
                  className="img-fluid"
                  alt="../Assets/family-mission-1.png"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Index;
