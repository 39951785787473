import React from 'react'
import { Link } from 'react-router-dom';
import { removeSubstrings } from '../../utils/CommanFun';
import personImg from "../../Assets/man.png";
import girl from "../../Assets/girl.png"
import moment from 'moment';

const MemberCard = ({ member, generation, generationTitle }) => {

    return (
        <div className='mt-4'>
            <div>
                {
                    member.some((ele) => ele.Generation === parseInt(generation)) && <div className="Generation-title mt-2">
                        <h2>{generationTitle}</h2>
                        <p>The wisdom of our ancestors is in the simile</p>
                    </div>
                }
                <div className="row gy-4 isotope-container" >
                    {member.map(({ Id, Name, DOB, Generation, Gender, Img }) => {
                        return Generation === parseInt(generation) ? (
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "#52565e",
                                }}
                                to={{ pathname: `/allmembers/${Id}` }}
                                className="col-lg-3 col-sm-6 col-6"
                                key={Id}
                            >
                                <article className="position-relative overflow-hidden rounded-3 card-ui">
                                    <div className="post-img position-relative overflow-hidden">
                                        <img style={{ opacity: Img ? "1" : "0.6" }} src={Img ? Img : Gender === "male" ? personImg : girl} alt="N/A" />
                                    </div>

                                    <div className="post-content d-flex flex-column align-items-center justify-content-between p-4">
                                        <h3
                                            className="post-title mb-0 fs-5 fw-bolder"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            {removeSubstrings(
                                                Name.split(" ")[0] +
                                                " " +
                                                Name.split(" ")[2]
                                            )}
                                        </h3>
                                        {
                                            DOB && <span className="d-block fw-bolder">
                                                {moment(DOB, "DD MMM YYYY", true).isValid() ? DOB : moment(DOB, "DD-MM-YYYY").format("DD MMM YYYY")}
                                            </span>
                                        }
                                    </div>
                                </article>
                            </Link>
                        ) : (
                            <></>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default MemberCard
