import React from "react";
import "./Footer.css";

function Footer() {

  return (
    <div>
      <footer id="footer">
        <div className="container">
          <h3>
            <span style={{ color: "#feb900" }}>F</span>amily{" "}
            <span style={{ color: "#feb900" }}>L</span>egacy
          </h3>
          <p>
            Our Family Legacy - a living legacy of laughter, enduring love, and timeless memories
          </p>
          <hr />
          <div className="justify-content-between text-center">
            <div className="copyright">
              <span> &copy; Copyright{" "}<strong> <span>Family Legacy</span></strong>&nbsp;Developed by&nbsp;</span>
              <span className="footer-Link" onClick={() => window.open("https://www.srkay.com/", "_blank")}>SRKAY Consulting Group</span>
            </div>
            {/* <div className="social-links">
              <a
                href="https://x.com/srkaycgr?s=11&t=kGAmSXAuYNOdH6AA2bZMPQ"
                className="twitter"
              >
                <i className="bi bi-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/SRKayCG?mibextid=LQQJ4d"
                className="facebook"
              >
                <i className="bi bi-facebook"></i>
              </a>
              <a href="https://www.instagram.com/srkay_consulting_group?igsh=MXNidGh3cnVsaHA4ZQ==" className="instagram">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/company/srkay-consulting-group/" className="linkedin">
                <i className="bi bi-linkedin"></i>
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
