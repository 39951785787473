export function truncateString(str, maxLength) {
    if (str?.length > maxLength) {
        return str?.substring(0, maxLength) + '...';
    }
    return str;
}

export function removeSubstrings(inputString) {
    const pattern =
        /bhai|ben|kumar|kumari/g
    ; return inputString.toLowerCase().replace(pattern, "");
}