import React from 'react'
import "./About.css"
import { Link } from 'react-router-dom'

function about() {
  return (
  <>
  <section className='about-family section'>
  <div className="container section-title" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
  <h2>Our Family</h2>
  <p>Celebrating our Culture, Principles, and Legacy </p>
</div>
  <div className="about-content container">

<div className="tab-pane fade active show">
  <div className="row">
    <div className="col-lg-6 col-12 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <h3>Who we are ?</h3>
      <p className="fst-italic">
      We are a diverse and close-knit family dedicated to preserving our cultural heritage and values. Our story spans generations, and we take pride in our shared history. 
      </p>
      <ul>
        <li><i className="bi bi-check2-all"></i>
          <spab>Family is the cornerstone of our lives. We prioritize love, support, and togetherness.</spab>
        </li>
        <li><i className="bi bi-check2-all"></i> <span>We value respect for one another and for our cultural roots. </span>.</li>
        <li><i className="bi bi-check2-all"></i> <span>Giving back to the community and supporting one another is a fundamental principle.</span></li>
        <li><i className="bi bi-check2-all"></i> <span>We honor and uphold our traditions, passing them on to the next generation.</span></li>
      </ul>
      <Link to="/history" className="read-more"><span>Read More</span><i className="bi bi-arrow-right"></i></Link>
    </div>
    <div className="col-lg-6 order-1 order-lg-2 text-center about-content-img" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <img src="../../Assets/splash 2.jpg" style={{width:"125%"}} alt="N/A" />
    </div>
  </div>
</div>
</div>
  </section>
  </>
  )
}

export default about
