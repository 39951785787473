import React from "react";
import "../../Components/GalleryComp/Gallery.css";
import "../../Components/GalleryComp/Recentgallery.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Header from "../../Components/Layout/Header";
import GalleryImagesCard from "../../Components/GalleryComp/GalleryImagesCard";
import useGallery from "../../Hooks/Gallery/useGallery";

function Index() {
  const { value, data, startDate, setStartDate, endDate, setEndDate, setValue, setDateRange } = useGallery();

  return (
    <div>
      <main className="main">
        <Header title="Gallery" description="Browse our collection of family photos and videos, categorized for easy viewing. Relive our family's history through memorable vacations, celebrations, and special moments." currentPage="Gallery" />
        <section id="album-gallery" className="album-gallery section pt-0" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000" >
          <div className="container pt-0" >
            <div className="filter-album row">
              <TabContext value={value}>
                <Box className="tabbing">
                  <div className="row gap-0 mt-2 gy-3 ">
                    <div className="col-md-7 col-xs-12 ">
                      <TabList
                        onChange={(e, value) => { setValue(value);window.scrollTo(0, 0); setDateRange(value) }}
                        aria-label="lab API tabs example"
                        scrollButtons="auto"
                        variant="scrollable"
                        allowScrollButtonsMobile
                      >
                        <Tab label="All" value="all" />
                        <Tab label="1960-1970" value="1960-1970" />
                        <Tab label="1971-1980" value="1971-1980" />
                        <Tab label="1981-1990" value="1981-1990" />
                        <Tab label="1991-2000" value="1991-2000" />
                        <Tab label="2001-2010" value="2001-2010" />
                        <Tab label="2011-2020" value="2011-2020" />
                      </TabList>
                    </div>
                    <div className="col-md-5 col-xs-12 gx-5">
                      <div className="d-flex gap-4 pb-2">
                        <input
                          type="date"
                          className="form-control custom-calendar card"
                          onChange={(e) => setStartDate(e.target.value)}
                          placeholder="Search..."
                          value={startDate}
                        />
                        <input
                          type="date"
                          className="form-control custom-calendar card"
                          onChange={(e) => setEndDate(e.target.value)}
                          placeholder="Search..."
                          value={endDate}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
                <TabPanel value="all">
                  <GalleryImagesCard data={data} />
                </TabPanel>
                <TabPanel value="1960-1970">
                  <GalleryImagesCard data={data} />
                </TabPanel>
                <TabPanel value="1971-1980">
                  <GalleryImagesCard data={data} />
                </TabPanel>
                <TabPanel value="1981-1990">
                  <GalleryImagesCard data={data} />
                </TabPanel>
                <TabPanel value="1991-2000">
                  <GalleryImagesCard data={data} />
                </TabPanel>
                <TabPanel value="2001-2010">
                  <GalleryImagesCard data={data} />
                </TabPanel>
                <TabPanel value="2011-2020">
                  <GalleryImagesCard data={data} />
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Index;
