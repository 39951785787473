import React from "react";
import "./Ourbusiness.css";
import data from './BussinessData.json'
import { useNavigate } from "react-router-dom";

function Ourbusiness() {
  const navigate = useNavigate()
  return (
    <>
      <section id="services" className="services section our-business">
        <div className="container section-title" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
          <h2>Family Businesses</h2>
          <p>
            A family's passion, a business born, with love, care, and dedication
          </p>
        </div>
        <div className="container"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">

          <div className="row gy-4">
            {data.businessDetail.map(({ id, Industry_Title, svgString }) => {
              return (<div className="col-lg-3 col-md-4 col-sm-6 col-xs-6" key={id}>
                <div className="service-item pointer position-relative card-ui" onClick={()=>navigate("/businesslist",{state:{
                  Business:Industry_Title
                }})}>
                  <div className="icon" style={{ position: "relative" }}>
                    <img src={svgString} style={{ zIndex: "100000", width: "70px", position: "absolute" }} alt="" />
                  </div>
                  <h3>{Industry_Title}</h3>
                </div>
              </div>)
            })}
          </div>
        </div>
      </section>

    </>
  );
}

export default Ourbusiness;
