import { useEffect,useCallback, useMemo, useState } from 'react';
import images from "../../Components/GalleryComp/galleryImages.json";

const useGallery = () => {
    const [data, setData] = useState(images.album)
    const [value, setValue] = useState("all");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if (startDate || endDate) {
            const today = new Date();
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : today;
            const album = images.album?.filter(album => {
                const albumDate = new Date(album.date);
                if (start && end) {
                    return albumDate >= start && albumDate <= end;
                } else if (start) {
                    return albumDate >= start && albumDate <= today;
                } else if (end) {
                    return albumDate <= end;
                } else {
                    return true;
                }
            });
            setData(album)
        }else{
            setData(images.album)
        }

    }, [startDate, endDate])

    const setDateRange = useCallback((value) => {
        switch (value) {
            case '1960-1970':
                setStartDate('01-01-1960');
                setEndDate('12-31-1970');
                break;
            case '1971-1980':
                setStartDate('01-01-1971');
                setEndDate('12-31-1980');
                break;
            case '1981-1990':
                setStartDate('01-01-1981');
                setEndDate('12-31-1990');
                break;
            case '1991-2000':
                setStartDate('01-01-1991');
                setEndDate('12-31-2000');
                break;
            case '2001-2010':
                setStartDate('01-01-2001');
                setEndDate('12-31-2010');
                break;
            case '2011-2020':
                setStartDate('01-01-2011');
                setEndDate('12-31-2020');
                break;
            default:
                setStartDate(null);
                setEndDate(null);
                setData(images.album);
        }
    }, []);

    return useMemo(() => ({
        data,
        value,
        setValue,
        setDateRange,
        startDate,
        setStartDate,
        endDate,
        setEndDate
    }), [value, data, startDate, setStartDate, endDate, setEndDate, setValue, setDateRange]);
};

export default useGallery;