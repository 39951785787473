import React, { useEffect, useState } from "react";
import "./Viewalbum.css"
import { useParams } from "react-router-dom";
import data from "./galleryImages.json";
import GLightbox from "glightbox";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Header from "../Layout/Header";
function Viewalbum() {
  const [value, setValue] = useState("photos")
  const { id: paramid } = useParams();
  let filterData = data.album.filter(({ id }) => {
    return id === parseInt(paramid);
  });

  useEffect(() => {
    GLightbox({
      selector: ".glightbox",
    });
  });

  return (
    <div>
      <main className="main">
        <Header title={filterData[0].title} description="" currentPage={filterData[0].title} />
        <section id="viewAlbum" className="viewAlbum section" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
          <div className="container">
            <div
              className="isotope-layout"
              data-default-filter="*"
              data-layout="masonry"
              data-sort="original-order"
            >
              <div
                className=" row isotope-container g-2"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
              >
                <TabContext value={value}>
                  <Box className="pt-2">
                    <div className="row tabbing gap-0 mt-2">
                      <div className="col-md-12 col-xs-12 ">
                        <TabList onChange={(e, value) => setValue(value)}
                          aria-label="lab API tabs example"
                          scrollButtons="auto"
                          variant="scrollable"
                          allowScrollButtonsMobile>
                          <Tab label="Photos" value="photos" />
                          <Tab label="Video" value="video" />
                        </TabList>
                      </div>
                    </div>
                  </Box>
                  <TabPanel value="photos">
                    <div className="row">
                      {filterData[0].images.map((img, id) => {
                        return (
                          <div
                            key={id}
                            className="col-lg-4 col-md-6 gallery-item isotope-item filter-remodeling"
                          >
                            <a
                              href={img}
                              title="App 1"
                              data-gallery="portfolio-gallery-app"
                              className="glightbox preview-link"
                            >
                              <div className="album-img w-100">
                                <img src={img} className="img-fluid" alt="" />
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                  <TabPanel value="diamond_industry">
                  </TabPanel>
                  <TabPanel value="it_industry">
                  </TabPanel>
                  <TabPanel value="solar_industry">
                  </TabPanel>
                </TabContext>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Viewalbum;
