export const LifestoryData = [
    {
        "lid":1,
        "lifestory": [
            {
                "title": "Born",
                "year": "1947",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Education",
                "year": "1955 - 1977",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "job",
                "year": "1977 - 1980",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Marriage",
                "year": "1979",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Became an enterprenour",
                "year": "1980",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            }
        ]
    },
    {
        "lid":2,
        "lifestory": [
            {
                "title": "Born",
                "year": "1960",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Education",
                "year": "1964 - 1973",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Marriage",
                "year": "1975",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            }
        ]
    },
    {
        "lid":3,
        "lifestory": [
            {
                "title": "Born",
                "year": "1982",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Education",
                "year": "1986 - 2000",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "job",
                "year": "2000 - 2005",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Marriage",
                "year": "2002",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Became an enterprenour",
                "year": "2006",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            }
        ]
    },
    {
        "lid":4,
        "lifestory": [
            {
                "title": "Born",
                "year": "2001",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Education",
                "year": "2005 - 2023",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Marriage",
                "year": "2024",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            }
        ]
    },
    {
        "lid":5,
        "lifestory": [
            {
                "title": "Born",
                "year": "2009",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            },
            {
                "title": "Education",
                "year": "2012-2024",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            }
        ]
    },
    {
        "lid":6,
        "lifestory": [
            {
                "title": "Born",
                "year": "2022",
                "description": "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project."
            }
        ]
    }
]