import React, { useEffect } from "react";
import img1 from "../../Assets/gallery-1.png"
import img2 from "../../Assets/savjikaka-1.png"
import img3 from "../../Assets/gallery-3.png"
import img4 from "../../Assets/savjikaka-2.png"
import img5 from "../../Assets/gallery-5.png"
import img6 from "../../Assets/gallery-6.png"
import GLightbox from "glightbox";

function Recentgallery() {
  useEffect(() => {
    GLightbox({
      selector: ".glightbox",
      loop: true,
      closeButton: true,
      plugins: {
        // Customize the footer content
        footer: {
          displayDescription: false, // Display description (title) from `data-title` attribute
          displayCounter: false,    // Optionally hide the counter
        }
      }
    });
  });

  return (
    <div>
      <section id="Recent-gallery" className="Recent-gallery section" style={{ marginBottom: "0px" }}>
        <div className="container section-title" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
          <h2 className="">Family Snap</h2>
          <p>
            Through laughter and tears, our family album tells a lifetime of love and togetherness
          </p>
        </div>

        <div className="container">
          <div
          >
            <div
              className="row isotope-container g-2"
              data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000"
            >
              <div className="col-lg-4 col-md-6 gallery-item isotope-item filter-remodeling" >
                <div className="card-ui" style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                  <a
                    href={img1}
                    // data-gallery="portfolio-gallery-app"
                    className="glightbox preview-link"
                  >
                    <div className="gallery-item-img">
                      <img src={img1} alt="" />
                    </div>

                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 gallery-item isotope-item filter-remodeling" >
                <div className="card-ui" style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                  <a
                    href={img2}

                    // data-gallery="portfolio-gallery-app"
                    className="glightbox preview-link"
                  >
                    <div className="gallery-item-img">
                      <img src={img2} alt="" />
                    </div>

                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 gallery-item isotope-item filter-remodeling" >
                <div className="card-ui" style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                  <a
                    href={img3}

                    // data-gallery="portfolio-gallery-app"
                    className="glightbox preview-link"
                  >
                    <div className="gallery-item-img">
                      <img src={img3} alt="" />
                    </div>

                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 gallery-item isotope-item filter-remodeling" >
                <div className="card-ui" style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                  <a
                    href={img4}

                    // data-gallery="portfolio-gallery-app"
                    className="glightbox preview-link"
                  >
                    <div className="gallery-item-img">
                      <img src={img4} alt="" />
                    </div>

                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 gallery-item isotope-item filter-remodeling" >
                <div className="card-ui" style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                  <a
                    href={img5}

                    // data-gallery="portfolio-gallery-app"
                    className="glightbox preview-link"
                  >
                    <div className="gallery-item-img">
                      <img src={img5} alt="" />
                    </div>

                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 gallery-item isotope-item filter-remodeling">
                <div className="card-ui" style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                  <a
                    href={img6}

                    // data-gallery="portfolio-gallery-app"
                    className="glightbox preview-link"
                  >
                    <div className="gallery-item-img">
                      <img src={img6} alt="" />
                    </div>

                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Recentgallery;
