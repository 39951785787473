import React from "react";
import "../../Components/AllMembers/Allmembers.css";
import { useMember } from "../../Hooks/Members/useMember";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MemberCard from "../../Components/AllMembers/MemberCard";
import Header from "../../Components/Layout/Header";
import NotFound from "../../Components/Layout/NotFound";

function Index() {
  const { member, searchData, value, setValue } = useMember();

  return (
    <div>
      <main className="main">
        <Header title="Family Members" description="Discover our interconnected family tree that spans six generations. See the relationships between family members and explore their individual stories." currentPage="All Members" />
        <section id="Allmembers" className="Allmembers section mt-3">
          <div className="container-md container-fluid">
            <div className="isotope-layout">
              <div
                className="row gy-4 isotope-container"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
              >
                <div
                  className="container aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <div className="row gap-0 tabbing gy-3 pt-2">
                        <div className="col-md-8 col-xs-12"  >
                          <Box className="d-flex">
                            <TabList
                              onChange={(e, newValue) => { setValue(newValue); window.scrollTo(0, 0) }}
                              aria-label="lab API tabs example"
                              scrollButtons="auto"
                              variant="scrollable"
                              allowScrollButtonsMobile
                            >
                              <Tab label="All" value="all" />
                              <Tab label="1st Gen" value="1" />
                              <Tab label="2nd Gen" value="2" />
                              <Tab label="3rd Gen" value="3" />
                              <Tab label="4th Gen" value="4" />
                              <Tab label="5th Gen" value="5" />
                              <Tab label="6th Gen" value="6" />
                            </TabList>
                          </Box>
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <div className="searchbar">
                              <input className="search_input" type="text" name="" onChange={searchData} placeholder="Search..." />
                              <div className="search_icon"><i className="bi bi-search"></i></div>
                            </div>
                          </div>
                        </div>    
                      </div>
                      <TabPanel value="all">
                        {
                          member.length > 0 ? <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                            <MemberCard member={member} generation={1} generationTitle="Founding Generation" />
                            <MemberCard member={member} generation={2} generationTitle="Building the Legacy" />
                            <MemberCard member={member} generation={3} generationTitle="Expanding Horizons" />
                            <MemberCard member={member} generation={4} generationTitle="Passing the Torch" />
                            <MemberCard member={member} generation={5} generationTitle="Nurturing New Roots" />
                            <MemberCard member={member} generation={6} generationTitle="The Future Awaits" />
                          </div> : <NotFound />
                        }
                      </TabPanel>
                      <TabPanel value="1">
                        {
                          member.some((ele) => ele.Generation === parseInt(value)) ?
                            <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                              <MemberCard member={member} generation={value} generationTitle="Founding Generation" />
                            </div>
                            : <NotFound />
                        }
                      </TabPanel>
                      <TabPanel value="2">
                        {
                          member.some((ele) => ele.Generation === parseInt(value)) ?
                            <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                              <MemberCard member={member} generation={value} generationTitle="Building the Legacy" />
                            </div>
                            : <NotFound />
                        }
                      </TabPanel>
                      <TabPanel value="3">
                        {
                          member.some((ele) => ele.Generation === parseInt(value)) ?
                            <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                              <MemberCard member={member} generation={value} generationTitle="Expanding Horizons" />
                            </div>
                            : <NotFound />
                        }
                      </TabPanel>
                      <TabPanel value="4">
                        {
                          member.some((ele) => ele.Generation === parseInt(value)) ?
                            <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                              <MemberCard member={member} generation={value} generationTitle="Passing the Torch" />
                            </div>
                            : <NotFound />
                        }
                      </TabPanel>
                      <TabPanel value="5">
                        {
                          member.some((ele) => ele.Generation === parseInt(value)) ?
                            <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                              <MemberCard member={member} generation={value} generationTitle="Nurturing New Roots" />
                            </div>
                            : <NotFound />
                        }
                      </TabPanel>
                      <TabPanel value="6">
                        {
                          member.some((ele) => ele.Generation === parseInt(value)) ?
                            <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                              <MemberCard member={member} generation={value} generationTitle="The Future Awaits" />
                            </div>
                            : <NotFound />
                        }
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
export default Index;
