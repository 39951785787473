import { useCallback, useMemo, useState } from "react";
import memberData from "../../Components/AllMembers/memberData.json";
import imgData from "../../Components/AllMembers/Person_album.json";
import { LifestoryData } from "../../Components/AllMembers/LifestoryData";
import { useParams } from "react-router-dom";
import busineesData from '../../Components/OurBusiness/BussinessData.json';
export const useMember = () => {

  let [member, setMember] = useState(memberData?.Member);
  const [value, setValue] = useState('all');
  const { id } = useParams();

  // this function handel search input of memberList
  let searchData = useCallback(({ target: { value } }) => {
    let filterData = memberData?.Member.filter(({ Name }) => { return Name.toLowerCase().includes(value.toLowerCase()) })
    setMember(filterData)
  }, []);

  // this function handel fetch image of person
  let getPersonsImages = imgData.filter(({ pid }) => {
    return pid === parseInt(id);
  })[0];


  const getFullPersonData = (personId) => {
    const getFamilyData = (personId) => {
      const person = memberData?.Member?.find((member) => member.Id === personId);
      if (!person) {
        return null;
      }

      // Initialize empty object for full person data
      const fullPersonData = { ...person };

      // Fetch spouse data if available
      if (person.spouse) {
        const spouse = memberData?.Member?.find(
          (member) => member.Id === person.spouse.rid
        );
        if (spouse) {
          fullPersonData.spouse = {
            ...spouse,
            relation: person.spouse.relation,
          };
        }
      }

      // Fetch children data if available
      if (person.children && person.children.length > 0) {
        fullPersonData.children = person.children.map((child) => {
          const childPerson = memberData?.Member.find(
            (member) => member.Id === child.rid
          );
          return { ...childPerson, relation: child.relation };
        });
      }

      // Fetch paternal and maternal data if available
      if (person.paternal) {
        const paternal = memberData?.Member.find(
          (member) => member.Id === person.paternal
        );
      
        if (paternal) {
          fullPersonData.paternal = {
            ...paternal,
            relation: "Father",
          };
        }
      }
      if (person.maternal) {
        const maternal = memberData?.Member.find(
          (member) => member.Id === person.maternal
        );
        if (maternal) {
          fullPersonData.maternal = {
            ...maternal,
            relation: "Mother",
          };
        }
      }
      return fullPersonData;
    };

    // Get full family data recursively
    return getFamilyData(personId);
  };

  function mergeLifeStory(personsData) {
    const lifeStoryData = LifestoryData.find(lifeStory => lifeStory?.lid === personsData?.lifestory_id);
    if (lifeStoryData) {
      personsData.lifestory = lifeStoryData?.lifestory;
    }
    const businesses = personsData?.business && personsData?.business.map(b => {
      const industry = busineesData?.businessDetail.find(ind => ind.Iid === b.Industry_id);
      if (industry) {
        const businessDetail = industry.companies.find(bus => bus.id === b.business_id);
        return businessDetail ? { ...businessDetail, name: personsData.name } : null;
      }
      return null;
    }).filter(Boolean);
    if (businesses) {
      personsData.business = businesses || []
    }
  }

  let personsData = getFullPersonData(parseInt(id));
  mergeLifeStory(personsData);

  return useMemo(() => ({
    member,
    value,
    setValue,
    setMember,
    searchData,
    getPersonsImages,
    personsData
  }), [member, setMember, searchData, getPersonsImages, personsData, value, setValue])
}
